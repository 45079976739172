<template>
  <dashboard>
    <el-row :gutter="15" class="mb-3">
      <el-col :xs="20" :sm="16">
        <h4 class="ml-1 mb-b bold orange-text">Your hired freelancers</h4>
      </el-col>
      <el-col :xs="4" :sm="8">
        <div class="add-job text-right">
          <router-link to="/employer/freelancers">
            <el-button type="warning" class="title-button-mobile"><i class="el-icon-search"></i><span class="hidden-xs">Search freelancers</span></el-button>
          </router-link>
        </div>
      </el-col>
    </el-row>
    <hr>
    <el-row :gutter="15">
      <el-col :xs="24">
        <div class="freelancer-profile">
          <router-link to="/employer/profile/" exact class="no-underline-hover">
            <el-card>
              <el-row :gutter="15" type="flex">
                <el-col :span="3">
                  <img src="/images/userimage.svg" class="w-100">
                </el-col>
                <el-col :span="18" class="align-self-center">
                  <p class="bold small mb-1">Jane Doe</p>
                  <el-rate
                      v-model="value"
                      disabled>
                  </el-rate>
                  <p class="small mb-2 mt-1"><span class="bold gray-text">Intro:</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae pretium eros. </p>
                  <p class="small mb-2"><span class="bold gray-text">Skills:</span></p>
                  <el-tag type="info">Laravel</el-tag> <el-tag type="info">Vue.js</el-tag> <el-tag type="info">PHP</el-tag>
                </el-col>
                <el-col :span="3" class="text-center text-lg-right">
                  <el-button type="warning" size="small" class="" plain circle icon="el-icon-more"></el-button>    
                </el-col>
              </el-row>
            </el-card>
          </router-link>
          <el-card>
            <el-row :gutter="15" type="flex">
              <el-col :span="3">
                <img src="/images/userimage.svg" class="w-100">
              </el-col>
              <el-col :span="18" class="align-self-center">
                <p class="bold small mb-1">Jane Doe</p>
                <el-rate
                    v-model="value"
                    disabled>
                </el-rate>
                <p class="small mb-2 mt-1"><span class="bold gray-text">Intro:</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae pretium eros. </p>
                <p class="small mb-2"><span class="bold gray-text">Skills:</span></p>
                <el-tag type="info">Laravel</el-tag> <el-tag type="info">Vue.js</el-tag> <el-tag type="info">PHP</el-tag>
              </el-col>
              <el-col :span="3" class="text-center text-lg-right">
                <el-button type="warning" size="small" class="" plain circle icon="el-icon-more"></el-button>    
              </el-col>
            </el-row>
          </el-card>
          <el-card>
            <el-row :gutter="15" type="flex">
              <el-col :span="3">
                <img src="/images/userimage.svg" class="w-100">
              </el-col>
              <el-col :span="18" class="align-self-center">
                <p class="bold small mb-1">Jane Doe</p>
                <el-rate
                    v-model="value"
                    disabled>
                </el-rate>
                <p class="small mb-2 mt-1"><span class="bold gray-text">Intro:</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae pretium eros. </p>
                <p class="small mb-2"><span class="bold gray-text">Skills:</span></p>
                <el-tag type="info">Laravel</el-tag> <el-tag type="info">Vue.js</el-tag> <el-tag type="info">PHP</el-tag>
              </el-col>
              <el-col :span="3" class="text-center text-lg-right">
                <el-button type="warning" size="small" class="" plain circle icon="el-icon-more"></el-button>    
              </el-col>
            </el-row>
          </el-card>
          <el-card>
            <div class="job-pagination text-center mb-0">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="50">
              </el-pagination>
            </div>  
          </el-card>
        </div>

        
      </el-col>
    </el-row>    
    
  </dashboard>
</template>

<script>
  import Dashboard from '~/components/Dashboard/Dashboard'

  export default {
    components: {
      Dashboard,
    },
   data() {
    return {
      value: 5,
      options: [{
            value: 'Option1',
            label: 'Option1'
          }, {
            value: 'Option2',
            label: 'Option2'
          }, {
            value: 'Option3',
            label: 'Option3'
          }, {
            value: 'Option4',
            label: 'Option4'
          }, {
            value: 'Option5',
            label: 'Option5'
          }],
          value1: [],
          value2: [],
          value3: [],
        }
      }

  }
    
</script>

<style lang="scss">
  .jobfeed {
    .search {
      padding: 30px;
    }
    .el-input-group__append button.el-button, .el-input-group__append, .el-input-group__prepend {
      color: #FFF;
      background-color: #E87722;
      border-color: #E87722;
      
    }
    .el-pagination {
      text-align: center;
    }
  }

</style>